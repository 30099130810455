import { Input, useToast } from "@chakra-ui/react";
import LabelAndCustomSelect from "../CommonComponents/CustomInputWithLabel/LabelAndCustomSelect";
import { STRINGS } from "../Utility/StringsEn";

const PerformanceTuningSection = ({ renderingData, onChange }) => {
  const videoResolutionOptions = [
    {
      label: "High",
      value: "high",
    },
    {
      label: "Medium",
      value: "medium",
    },
    {
      label: "Low",
      value: "low",
    },
  ];
  const preferredCodecOptions = [
    {
      label: "AV1",
      value: "AV1",
    },
    {
      label: "VP9",
      value: "VP9",
    },
    {
      label: "VP8",
      value: "VP8",
    },
    {
      label: "H264",
      value: "H264",
    },
  ];

  const toast = useToast();

  const handleChange = (e, fieldName) => {
    const value = e.target.valueAsNumber;
    const isValid =
      value === "" || (Number(value) >= -1 && Number(value) !== 0);

    if (!isValid) {
      toast({
        title: "Invalid value",
        description: `Active video streams must be a valid positive number or -1.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    onChange(
      {
        ...renderingData,
        [fieldName]: Number(value) || "",
      },
      fieldName,
      isValid
    );
  };

  return (
    <div className="w-full flex flex-col items-start justify-start gap-[1.5rem] max-w-[77.5rem] text-left text-[1.25rem] text-darkslategray-200 font-heebo mq1366-1281:gap-[1.5rem] mq1280-961:gap-[1.5rem] Tablet:gap-[1.25rem] Mobile:gap-[1.5rem] small_mobile:gap-[1rem]">
      <b className="h-[1.375rem] relative tracking-[0.02em] leading-[1.375rem] inline-block mq1366-1281:text-[1.125rem] mq1366-1281:leading-[1.25rem] mq1280-961:text-[1.125rem] mq1280-961:leading-[1.25rem] Tablet:text-[1.125rem] Tablet:leading-[1.25rem] Mobile:text-[1rem] Mobile:leading-[1.125rem] small_mobile:text-[0.875rem] small_mobile:leading-[1rem]">
        Performance Tuning
      </b>
      <div className="self-stretch grid flex-row items-center justify-start gap-[1.5rem] grid-cols-[repeat(2_,1fr)] text-[1rem] Tablet:grid Tablet:grid-cols-[repeat(1_,1fr)] Mobile:grid Mobile:grid-cols-[repeat(1_,1fr)] small_mobile:grid small_mobile:grid-cols-[repeat(1_,1fr)]">
        <LabelAndCustomSelect
          label={STRINGS.videoResolution}
          renderingOptions={videoResolutionOptions}
          fieldName={"video_resolution"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              video_resolution: data.target.value,
            });
          }}
          value={renderingData.video_resolution}
        />

        <LabelAndCustomSelect
          label={STRINGS.preferredCodec}
          renderingOptions={preferredCodecOptions}
          fieldName={"codec"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              codec: data.target.value,
            });
          }}
          value={renderingData.codec}
        />
      </div>
      <div className="self-stretch grid flex-row items-center justify-start gap-[1.5rem] grid-cols-[repeat(2_,1fr)] text-[1rem] Tablet:grid Tablet:grid-cols-[repeat(1_,1fr)] Mobile:grid Mobile:grid-cols-[repeat(1_,1fr)] small_mobile:grid small_mobile:grid-cols-[repeat(1_,1fr)]">
        <div className="flex-1 flex flex-row items-center justify-between max-w-[22.5rem] Mobile:flex-col Mobile:gap-[0.25rem] Mobile:items-start Mobile:justify-start Mobile:max-w-[37.5rem] small_mobile:flex-col small_mobile:gap-[0.25rem] small_mobile:items-start small_mobile:justify-start">
          <div className="flex flex-row items-center justify-start max-w-[20rem] Mobile:min-w-[16.875rem] small_mobile:min-w-[10rem]">
            <div className="flex flex-row items-center justify-start">
              <div className="relative tracking-[-0.02em] font-medium mq1366-1281:text-[0.875rem] mq1280-961:text-[0.875rem] Tablet:text-[0.875rem] Mobile:text-[0.875rem] small_mobile:text-[0.75rem] small_mobile:leading-[0.875rem]">
                {STRINGS.activeVideoStreams}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start relative gap-[1rem] text-[0.688rem] Mobile:self-stretch Mobile:w-auto">
            <Input
              className="bg-[transparent] w-[10rem] font-heebo text-[1rem] text-gray-300 z-[0] sm:min-w-[0rem] Mobile:flex-1 Mobile:w-full small_mobile:flex-1"
              placeholder="Enter number"
              width="160px"
              w="160px"
              type="number"
              value={renderingData.active_numberof_streams}
              onChange={(data) => handleChange(data, "active_numberof_streams")}
            />
            <div className="flex flex-col items-end justify-end absolute gap-[0.375rem] right-[-1.875rem] z-[1] [&_.tooltip]:hover:flex Mobile:[position:unset]">
              <img
                className="w-[1.25rem] relative h-[1.25rem] overflow-hidden shrink-0 z-[0]"
                alt=""
                src="/info.svg"
              />
              <div className="tooltip shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] rounded bg-white box-border overflow-hidden hidden flex-row items-center justify-end py-[0.25rem] px-[0.5rem] min-w-[11.25rem] absolute right-[1rem] bottom-[1rem] z-[1] border-[1px] border-solid border-whitesmoke-600 hover:mb-7 hover:absolute hover:right-[0px]">
                <div className="flex-1 relative tracking-[-0.02em] font-medium mq1366-1281:text-[0.875rem] mq1280-961:text-[0.875rem] Tablet:text-[0.875rem] Mobile:text-[0.875rem] small_mobile:text-[0.75rem] small_mobile:leading-[0.875rem]">
                  Default value is -1 (Means all available streams will be
                  active)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceTuningSection;
