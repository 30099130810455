import { Select } from "@chakra-ui/react";
const CustomSelect = ({
  fieldName,
  isRequired,
  renderingOptions,
  isDisabled,
  onChange,
  value,
}) => {
  const keyGenerator = (label, index) => {
    return `${fieldName.toLowerCase().replace(" ", "-")}_${label}_${index}`;
  };
  return (
    <Select
      className="w-[10rem] font-heebo text-[1rem] text-gray-300 sm:min-w-[0rem] Mobile:self-stretch Mobile:w-full small_mobile:self-stretch small_mobile:w-auto"
      name={fieldName}
      required={isRequired}
      disabled={isDisabled}
      onChange={onChange}
      defaultValue={value}
    >
      {renderingOptions?.map((option, index) => {
        return (
          <option key={keyGenerator(option.value, index)} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </Select>
  );
};

export default CustomSelect;
