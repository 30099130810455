import { createContext, useEffect, useState } from "react";
// import Search from "../../CommonComponents/CustomSearch/Search";
import CustomTabs from "../../CommonComponents/CustomTabs/CustomTabs";
import { STRINGS } from "../../Utility/StringsEn";
import {
  convertDateStringToMomentDate,
  convertMomentDateToGivenFormat,
} from "../../Utility/Util";

export const ModifiedServerIntanceTableData = createContext();
const MediaServerInstances = ({ instanceData }) => {
  const [modifiedTableData, setModifiedTableData] = useState([]);

  const generateTableData = () => {
    instanceData &&
      Object.keys(instanceData).map((instanceServer) => {
        let modifiedData = instanceData[instanceServer].map(
          (serverData, index) => {
            return {
              id: index + 1,
              ip_address: serverData.ip_address,
              cpu_usage: serverData.cpu_usage,
              ram_usage: serverData.ram_usage,
              meetings: serverData.meetings,
              is_healthy: serverData.is_healthy
                ? STRINGS.healthy
                : STRINGS.unhealthy,
              status: serverData.status,
              created_at: convertMomentDateToGivenFormat(
                convertDateStringToMomentDate(serverData.created_at)
              ).replace(",", ", \n"),
              last_served_at: convertMomentDateToGivenFormat(
                convertDateStringToMomentDate(serverData.last_served_at)
              ).replace(",", ", \n"),
            };
          }
        );
        instanceData[instanceServer] = modifiedData;
        return instanceData[instanceServer];
      });
    setModifiedTableData({ ...instanceData });
  };

  useEffect(() => {
    generateTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceData]);

  return (
    <div className="w-full bg-white overflow-hidden flex flex-col items-start justify-start py-[0.75rem] px-[1.25rem] box-border gap-[0.375rem] max-w-[77.5rem] text-left text-[1rem] text-darkslategray-200 font-heebo mq1280-961:gap-[1.5rem_0.375rem] Tablet:gap-[1.25rem_0.375rem] Tablet:pl-[1.25rem] Tablet:box-border Mobile:gap-[0.375rem] Mobile:py-[0.625rem] Mobile:px-[0rem] Mobile:box-border small_mobile:gap-[0.25rem_0.375rem] small_mobile:pl-[0rem] small_mobile:pr-[0rem] small_mobile:box-border">
      <div className="self-stretch flex flex-row items-start justify-between text-[1.25rem] Mobile:flex-col small_mobile:flex-col">
        <b className="h-[1.813rem] flex-1 relative tracking-[0.02em] inline-block mq1366-1281:text-[1.125rem] mq1366-1281:leading-[1.25rem] mq1280-961:text-[1.125rem] mq1280-961:leading-[1.25rem] Tablet:text-[1.125rem] Tablet:leading-[1.25rem] Mobile:text-[1rem] Mobile:leading-[1.125rem] Mobile:flex-[unset] Mobile:self-stretch small_mobile:text-[0.875rem] small_mobile:leading-[1rem] small_mobile:flex-[unset] small_mobile:self-stretch">
          {STRINGS.instances}
        </b>
        {/*  TODO: hidden for now, once the functionality is inplace we will show the Search box 
          <Search /> */}
      </div>
      <ModifiedServerIntanceTableData.Provider value={modifiedTableData}>
        <CustomTabs />
      </ModifiedServerIntanceTableData.Provider>
    </div>
  );
};

export default MediaServerInstances;
