import CustomSelect from "./CustomSelect";
const LabelAndCustomSelect = ({
  label,
  renderingOptions,
  fieldName,
  isFieldDisabled,
  onChange,
  value,
}) => {
  return (
    <div className="flex-1 flex flex-row items-center justify-between max-w-[22.5rem] Mobile:flex-col Mobile:gap-[0.25rem] Mobile:items-start Mobile:justify-start Mobile:max-w-[37.5rem] small_mobile:flex-col small_mobile:gap-[0.25rem] small_mobile:items-start small_mobile:justify-start">
      <div className="flex flex-row items-center justify-start max-w-[20rem] Mobile:min-w-[16.875rem] small_mobile:min-w-[10rem]">
        <div className="flex flex-row items-center justify-start">
          <div className="relative tracking-[-0.02em] font-medium mq1366-1281:text-[0.875rem] mq1280-961:text-[0.875rem] Tablet:text-[0.875rem] Mobile:text-[0.875rem] small_mobile:text-[0.75rem] small_mobile:leading-[0.875rem]">
            {label}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-start relative gap-[1rem] text-[0.688rem] Mobile:self-stretch Mobile:w-auto">
        <CustomSelect
          fieldName={fieldName}
          isRequired
          isDisabled={isFieldDisabled}
          renderingOptions={renderingOptions}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default LabelAndCustomSelect;
