import { STRINGS } from "../Utility/StringsEn";
import AdvancedFeaturesList from "./AdvancedFeaturesList";

const AdvancedFeaturesContainer = ({
  renderingData,
  onChange,
  isTranscriptSelected,
}) => {
  return (
    <div className="w-full flex flex-col items-start justify-start gap-[1.5rem] max-w-[77.5rem] text-left text-[1.75rem] text-darkslategray-200 font-heebo mq1600-1367:gap-[2rem] mq1366-1281:gap-[1.5rem] mq1280-961:gap-[1.5rem] Tablet:gap-[1.25rem] Mobile:gap-[1rem] small_mobile:gap-[1rem]">
      <b className="self-stretch h-[1.875rem] relative tracking-[0.02em] leading-[1.875rem] inline-block lg:text-[1.5rem] Tablet:text-[1.625rem] Tablet:leading-[1.75rem] Mobile:text-[1.25rem] Mobile:leading-[1.375rem] small_mobile:text-[1.125rem] small_mobile:leading-[1.25rem]">
        {STRINGS.advanceFeatures}
      </b>
      <AdvancedFeaturesList
        renderingData={renderingData}
        onChange={(event) => {
          onChange({ [event.target.name]: event.target.checked });
        }}
        isTranscriptSelected={isTranscriptSelected}
      />
    </div>
  );
};

export default AdvancedFeaturesContainer;
