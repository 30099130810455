import { STRINGS } from "../Utility/StringsEn";

export const FEATURES_CARD = [
  {
    icon: "admin-features-icons@2x.png",
    label: STRINGS.audioCalls,
    isEnabled: false,
    isSelected: true,
    name: "audio_call",
    description: STRINGS.audioCallDescription,
  },
  {
    icon: "iconvideocalls@2x.png",
    label: STRINGS.videoCalls,
    isEnabled: true,
    isSelected: true,
    name: "video_call",
    description: STRINGS.videoCallDescription,
  },
  {
    icon: "admin-features-icons4@2x.png",
    label: STRINGS.screenSharing,
    isEnabled: true,
    isSelected: true,
    name: "screen_sharing",
    description: STRINGS.screenSharingDescription,
  },
  {
    icon: "iconclientrecording@2x.png",
    label: STRINGS.clientSideRecording,
    isEnabled: true,
    isSelected: true,
    name: "client_side_recording",
    description: STRINGS.clientSideRecordingDescription,
  },

  {
    icon: "admin-features-icons7@2x.png",
    label: STRINGS.moderatorControl,
    isEnabled: true,
    isSelected: true,
    name: "moderator_control",
    description: STRINGS.moderatorControlDescription,
  },
  {
    icon: "admin-features-icons3@2x.png",
    label: STRINGS.waitingRoom,
    isEnabled: true,
    isSelected: true,
    name: "waiting_room",
    description: STRINGS.waitingRoomDescription,
  },

  {
    icon: "admin-features-icons8@2x.png",
    label: STRINGS.transcript,
    isEnabled: true,
    isSelected: true,
    name: "transcript",
    description: STRINGS.transcriptDescription,
  },

  {
    icon: "admin-features-icons13@2x.png",
    label: STRINGS.callSummary,
    isEnabled: true,
    isSelected: true,
    name: "call_summary",
    description: STRINGS.callSummaryDescription,
  },
  // Hidden features
  // {
  //   icon: "admin-features-icons1@2x.png",
  //   label: STRINGS.liveChat,
  //   isEnabled: true,
  //   isSelected: true,
  //   name: "live_chat",
  // },
  // {
  //   icon: "admin-features-icons9@2x.png",
  //   label: STRINGS.autoCentering,
  //   isEnabled: true,
  //   isSelected: true,
  //   name: "auto_centering",
  // },

  // {
  //   icon: "admin-features-icons5@2x.png",
  //   label: STRINGS.desktopStreaming,
  //   isEnabled: true,
  //   isSelected: true,
  //   name: "desktop_streaming",
  // },
  // {
  //   icon: "iconserverrecording@2x.png",
  //   label: STRINGS.serverSideRecording,
  //   isEnabled: true,
  //   isSelected: true,
  //   name: "server_side_recording",
  // },
];

export const ADVANCED_FEATURES_CARD = [
  {
    icon: "admin-features-icons14@2x.png",
    label: STRINGS.catchUpMeeting,
    isEnabled: true,
    isSelected: true,
    name: "catchup_meeting",
    description: STRINGS.catchUpMeetingDescription,
  },
  // Hidden features
  // {
  //   icon: "admin-features-icons15@2x.png",
  //   label: STRINGS.pipScreenSharing,
  //   isEnabled: true,
  //   isSelected: true,
  //   name: "pip_screen_sharing",
  // },
  // {
  //   icon: "admin-features-icons12@2x.png",
  //   label: "Remote Desktop Control",
  //   isEnabled: true,
  //   isDisabled: false,
  //   isSelected: true,
  // name:"remote_desktop_control"
  // },
];

export const USER_PLANS = {
  BASIC: "Basic",
  ENTERPRISE: "Enterprise",
};
