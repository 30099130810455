import { FEATURES_CARD } from "../Constants/AdminSettingsConstants";
import FeatureCard from "./FeatureCard";

const FeaturesList = ({
  renderingData,
  onChange,
  isModerateControlSelected,
}) => {
  const dependentFeatures = ["waiting_room"];
  return (
    <div className="self-stretch grid flex-col items-start justify-start gap-[1.5rem] grid-cols-[repeat(2,_1fr)] [column-gap:20px] text-left text-[1rem] text-darkslategray-200 font-heebo Tablet:grid Tablet:grid-cols-[repeat(1,_1fr)] Tablet:[column-gap:20px] Mobile:gap-[1rem] Mobile:grid Mobile:grid-cols-[repeat(1,_1fr)] Mobile:[column-gap:20px] small_mobile:gap-[1rem] small_mobile:grid small_mobile:grid-cols-[repeat(1,_1fr)] small_mobile:[column-gap:20px]">
      {FEATURES_CARD.map((featureCard, index) => {
        let isEnabled =
          isModerateControlSelected ||
          !dependentFeatures.includes(featureCard.name);

        return (
          <FeatureCard
            key={index}
            icon={featureCard.icon}
            label={featureCard.label}
            isChecked={renderingData?.[featureCard.name]}
            isDisabled={!featureCard.isEnabled || !isEnabled}
            name={featureCard.name}
            description={featureCard.description}
            onChange={onChange}
            renderingData={renderingData}
          />
        );
      })}
    </div>
  );
};

export default FeaturesList;
