import { STRINGS } from "../Utility/StringsEn";
import FeaturesList from "./FeaturesList";

const FeaturesContainer = ({
  renderingData,
  onChange,
  isModerateControlSelected,
}) => {
  return (
    <div className="w-full flex flex-col items-start justify-start gap-[1rem] max-w-[77.5rem] text-left text-[1.25rem] text-darkslategray-200 font-heebo mq1600-1367:gap-[2rem] mq1366-1281:gap-[1.5rem] mq1280-961:gap-[1.5rem] Tablet:gap-[1.25rem] Mobile:gap-[1rem] small_mobile:gap-[1rem]">
      <b className="h-[1.375rem] relative tracking-[0.02em] leading-[1.375rem] inline-block mq1366-1281:text-[1.125rem] mq1366-1281:leading-[1.25rem] mq1280-961:text-[1.125rem] mq1280-961:leading-[1.25rem] Tablet:text-[1.125rem] Tablet:leading-[1.25rem] Mobile:text-[1rem] Mobile:leading-[1.125rem] small_mobile:text-[0.875rem] small_mobile:leading-[1rem]">
        {STRINGS.features}
      </b>
      <FeaturesList
        renderingData={renderingData}
        onChange={(event) =>
          onChange({ [event.target.name]: event.target.checked })
        }
        isModerateControlSelected={isModerateControlSelected}
      />
    </div>
  );
};

export default FeaturesContainer;
