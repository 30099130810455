import React, { useContext } from "react";
import CustomTable from "../../CommonComponents/CustomTable/CustomTable";
import { STRINGS } from "../../Utility/StringsEn";
import { ModifiedServerIntanceTableData } from "./MediaServerInstances";

export default function MediaServerConatiner() {
  const contextData = useContext(ModifiedServerIntanceTableData);
  const headerCells = [
    {
      id: "id",
      isSort: false,
      numeric: false,
      disablePadding: true,
      label: STRINGS.srNo,
    },
    {
      id: "ip",
      isSort: false,
      numeric: true,
      disablePadding: false,
      label: STRINGS.ip,
    },
    {
      id: "cpu_usage",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: `${STRINGS.cpu} (%)`,
    },
    {
      id: "ram_usage",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: `${STRINGS.memory} (%)`,
    },
    {
      id: "meetings",
      isSort: false,
      numeric: true,
      disablePadding: false,
      label: STRINGS.meetings,
    },
    // {
    //   id: "remaining_capacity",
    //   isSort: true,
    //   numeric: true,
    //   disablePadding: false,
    //   label: STRINGS.remainingCapacity,
    // },
    {
      id: "is_healthy",
      isSort: false,
      numeric: false,
      disablePadding: false,
      label: STRINGS.healthCheck,
    },
    {
      id: "status",
      isSort: false,
      numeric: false,
      disablePadding: false,
      label: STRINGS.status,
    },
    // {
    //   id: "run_time",
    //   isSort: false,
    //   numeric: false,
    //   disablePadding: false,
    //   label: `${STRINGS.runTime} (${STRINGS.hhmm})`,
    //   renderCell: (value) => {
    //     return value ?? "--:--";
    //   },
    // },
    {
      id: "creation_time",
      isSort: false,
      numeric: false,
      disablePadding: false,
      label: STRINGS.creationTime,
    },
    {
      id: "last_served_at",
      isSort: false,
      numeric: false,
      disablePadding: false,
      label: STRINGS.lastServedAt,
    },
  ];
  return (
    contextData?.media_server && (
      <CustomTable
        headerCells={headerCells}
        rows={contextData?.media_server}
        sortByColumn={"cpu"}
      />
    )
  );
}
