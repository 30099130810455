import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
// import createCache from "@emotion/cache";
// import { CacheProvider } from "@emotion/react";
import { reduxStore } from "../src/components/Redux/store";
import "./global.css";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#1976D2",
    },
  },
});
const chakraTheme = extendTheme({
  styles: { global: { img: { maxWidth: "unset" } } },
  colors: {
    gray: {
      50: "#f7fafc",
      100: "#edf2f7",
      200: "#e2e8f0",
      300: "#cbd5e0",
      400: "#a0aec0",
      500: "#718096",
      600: "#4a5568",
      700: "#2c3748",
      800: "#1a202c",
      900: "#171923",
    },
    aa: {
      50: "#e8fcf5",
      100: "#bbf6e0",
      200: "#8ef0cb",
      300: "#61eab6",
      400: "#34e4a2",
      500: "#1de297",
      600: "#1bcb88",
      700: "#159e6a",
      800: "#0f714c",
      900: "#09442d",
    },
  },
});

// const emotionCache = createCache({
//   key: "emotion-cache",
//   prepend: true,
// });

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={reduxStore}>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          {/* <CacheProvider value={emotionCache}> */}
          <ChakraProvider
            theme={chakraTheme}
            toastOptions={{ defaultOptions: { position: "top-right" } }}
          >
            <CssBaseline />
            <App />
          </ChakraProvider>
          {/* </CacheProvider> */}
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
