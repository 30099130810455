import { Switch } from "@chakra-ui/react";

const FeatureCard = ({
  icon,
  label,
  isChecked,
  onChange,
  isDisabled,
  name,
  description,
}) => {
  return (
    <div className="self-stretch rounded-md bg-whitesmoke-100 flex flex-row items-center justify-between py-[15px] px-4 text-left text-base text-darkslategray-200 font-heebo Mobile:pt-3 Mobile:pr-4 Mobile:pb-3 Mobile:box-border small_mobile:h-auto small_mobile:pt-3 small_mobile:pr-4 small_mobile:pb-3 small_mobile:box-border small_mobile:max-w-[240px]">
      <div className="flex-1 flex flex-row items-center justify-start py-0 pr-2 pl-0">
        <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
          <img
            className="w-[42px] relative h-[42px] overflow-hidden shrink-0 object-cover Tablet:w-9 Tablet:h-9 Mobile:w-7 Mobile:h-7 small_mobile:w-6 small_mobile:h-6"
            alt=""
            src={icon}
          />
          <div className="flex flex-row items-center justify-start gap-[0.625rem] w-auto h-auto">
            <div className="h-auto relative tracking-[0.02em] leading-[1.125rem] capitalize font-medium inline-block w-auto flex-[1_1_auto] Tablet:text-[1rem] Tablet:leading-[1.125rem] Mobile:text-[1rem] Mobile:leading-[1.125rem] small_mobile:text-[0.875rem] small_mobile:leading-[1rem]">
              {label}
            </div>
            <div className="flex flex-col items-end justify-end relative gap-[0.375rem] text-[0.688rem] [&_.tooltip1]:hover:flex">
              <img
                className="w-[1rem] relative h-[1rem] overflow-hidden shrink-0 z-[0]"
                alt=""
                src="/info.svg"
              />
              <div className="tooltip1 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] rounded bg-white box-border overflow-hidden hidden flex-row items-center justify-end py-[0.25rem] px-[0.5rem] min-w-[11.25rem] absolute left-[1rem] bottom-[1rem] z-[1] border-[1px] border-solid border-whitesmoke-600 hover:mb-7 hover:absolute hover:right-[0px]">
                <div className="flex-1 relative tracking-[-0.02em] font-medium mq1366-1281:text-[0.875rem] mq1280-961:text-[0.875rem] Tablet:text-[0.875rem] Mobile:text-[0.875rem] small_mobile:text-[0.75rem] small_mobile:leading-[0.875rem]">
                  {description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch
        colorScheme="aa"
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={onChange}
        name={name}
      />
    </div>
  );
};

export default FeatureCard;
