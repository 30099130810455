import DropdownOptions from "../CommonComponents/CustomDropdown/DropdownOptions";
import { Menu, MenuButton } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import packageJson from "../../../package.json";

const Header = () => {
  return (
    <>
      <div className="self-stretch bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_ ̰0.11)] h-[75px] overflow-hidden shrink-0 flex flex-col items-center justify-center py-0 px-[34px] box-border z-[2] text-right text-base text-black font-heebo Mobile:pl-4 Mobile:pt-0 Mobile:pr-4 Mobile:box-border small_mobile:pl-4 small_mobile:pt-0 small_mobile:pr-4 small_mobile:box-border">
        <div className="hidden">{`Admin UI: v${packageJson.version}`}</div>
        <div className="w-full flex flex-row items-center justify-between max-w-[1240px]">
          <img
            className="w-[182.4px] relative h-12 object-cover Mobile:w-[118px] Mobile:h-[31px] small_mobile:w-[118px] small_mobile:h-[31px]"
            alt=""
            src="./proconf-logo-header@2x.png"
          />
          <div className="flex flex-row items-center justify-start gap-[8px] cursor-pointer Mobile:gap-[8px_4px] small_mobile:gap-[8px_4px]">
            <Menu>
              <MenuButton>
                <Flex justifyContent="start" alignItems="center">
                  <Flex justifyContent="start" alignItems="start">
                    <Flex
                      flexDirection="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      className="pr-2 Mobile:hidden small_mobile:hidden"
                    >
                      <div className="relative tracking-[0.02em] leading-[18px] Mobile:text-sm Mobile:leading-[16px]">
                        ProConf Admin
                      </div>
                      <div className="relative text-xs tracking-[0.02em] text-darkgray-300 Mobile:text-3xs Mobile:leading-[12px]">
                        Admin
                      </div>
                    </Flex>
                    <img
                      className="w-[42px] relative rounded-38xl h-[42px] overflow-hidden shrink-0 object-cover mr-2"
                      alt=""
                      src="./photo@2x.png"
                    />
                  </Flex>
                  <img
                    className="w-[11.7px] relative h-[7.4px]"
                    alt=""
                    src="./vector1.svg"
                  />
                </Flex>
              </MenuButton>
              <DropdownOptions />
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
