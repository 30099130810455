import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Login from "./components/Login/Login";
import AdminSettings from "./components/KeyFeatureSettings/AdminSettings";
import AdminDashboard from "./components/Dashboard/AdminDashboard";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/settings":
        title = "";
        metaDescription = "";
        break;
      case "/dashboard":
        title = "";
        metaDescription = "";
        break;
      default:
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);
  const AppRoutes = [
    {
      path: "/settings",
      Component: AdminSettings,
    },
    {
      path: "/dashboard",
      Component: AdminDashboard,
    },
  ];
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {AppRoutes.map((route, index) => {
        const { Component, ...rest } = route;
        return (
          <Route element={<PrivateRoutes {...rest} />} key={`route-${index}`}>
            <Route
              key={index}
              path={route?.path}
              element={<Component />}
              {...rest}
            />
          </Route>
        );
      })}
    </Routes>
  );
}
export default App;
