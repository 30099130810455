import { createContext, useEffect, useState } from "react";
import Header from "../Header/Header";
import PageHeading from "../CommonComponents/TopNavigation/PageHeading";
import UsageStatisticsContainer from "./Statistics/UsageStatisticsContainer";
import MediaServerInstances from "./MediaServerInstances/MediaServerInstances";
import Footer from "../Footer/Footer";
import { useDispatch } from "react-redux";
import { getInstances, getStatistics } from "../Redux/Actions/DashboardActions";
import { STRINGS } from "../Utility/StringsEn";

export const StatisticsContext = createContext();

const AdminDashboard = () => {
  const dispatch = useDispatch();

  const [statisticsData, setstatisticsData] = useState(null);
  const [instanceData, setInstanceData] = useState([]);

  const getLatestStatisticsData = () => {
    dispatch(getStatistics((response) => setstatisticsData(response)));
  };
  const getInstancesData = () => {
    dispatch(
      getInstances((response) => {
        setInstanceData(response?.data);
      })
    );
  };

  useEffect(() => {
    getLatestStatisticsData();
    getInstancesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full h-screen relative flex flex-col items-center justify-between text-left text-[1.5rem] text-darkslategray-200 font-heebo">
      <div className="self-stretch flex-1 flex flex-col items-center justify-start z-[1]">
        <Header />
        <div className="self-stretch flex-1 bg-whitesmoke-200 overflow-hidden flex flex-col items-center justify-start pt-[1.25rem] px-[2.125rem] pb-[1.875rem] gap-[0.75rem] Tablet:gap-[0.75rem] Mobile:bg-white Mobile:gap-[0.75rem] Mobile:p-[1rem] Mobile:box-border small_mobile:bg-white small_mobile:gap-[0.5rem] small_mobile:py-[0.75rem] small_mobile:px-[1rem] small_mobile:box-border">
          <div className="w-full flex flex-row items-center justify-start max-w-[77.5rem]">
            <PageHeading title={STRINGS.dashboard} />
            <img
              className="w-6 cursor-pointer relative h-6 overflow-hidden shrink-0 Mobile:w-6 Mobile:h-6 small_mobile:w-5 small_mobile:h-5"
              alt=""
              src="/sync-icon.svg"
              onClick={() => {
                getLatestStatisticsData();
                getInstancesData();
              }}
            />
          </div>
          <StatisticsContext.Provider value={statisticsData}>
            <UsageStatisticsContainer
              getLatestStatisticsData={getLatestStatisticsData}
            />
          </StatisticsContext.Provider>
          <MediaServerInstances instanceData={instanceData} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminDashboard;
